<template>
    <div class="ocr-apple-container">
        <div class="scrollable-content" style="flex: 1;">
            <p v-if="ocrResult === '识别中...'">
                <i class="el-icon-loading"></i> {{ ocrResult }}
            </p>
            <p v-else-if="ocrError">
                <i class="el-icon-error"></i> {{ ocrResult }}
            </p>
            <p v-else v-html="formattedOcrResult"></p>
        </div>
        <el-divider class="line50"></el-divider>
        <div style="display: flex;justify-content: center;width: 100%; height: 64px;align-items: center">
            <!-- <i class="el-icon-copy-document btn" @click="copyResult"></i> -->
            <el-button class="btn" icon="el-icon-copy-document" round size="small" @click="copyResult">Copy</el-button>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        // 新增一个计算属性来处理换行
        formattedOcrResult() {
            return this.ocrResult.replace(/\n/g, '<br>');
        }
    },
    props: {
        ocrResult: {
            type: String,
            default: ''
        },
        ocrError:{
            type: Boolean,
            default: false
        }
    },
    methods: {
        copyResult() {
            navigator.clipboard.writeText(this.ocrResult).then(() => {
                this.$message.success('结果已复制到剪贴板');
            }, (err) => {
                console.error('无法复制文本: ', err);
            });
        }
    }
}
</script>

<style scoped>
.ocr-apple-container {
    height: 100%;
    padding: 0px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.ocr-apple-container p {
    color: rgb(55, 53, 47);
    white-space: pre-wrap; /* 保留空白符序列，但是正常的换行符也会被识别并作为换行处理 */
}

.btn {
    background-color: transparent;
    color: rgb(55, 53, 47);
    font-size: 14px;
}

.btn:hover {
    border:1px solid rgb(55, 53, 47,0.4);
    transform: scale(1.05); /* 当鼠标悬停时放大5% */
    transition: transform 0.3s ease; /* 添加平滑过渡效果 */
}

::v-deep .btn i{
    font-size: 13px;
}


::v-deep .el-divider--horizontal 
{
    margin: 4px 0px;
}
</style>
