<template>
  <MainOCR/>
</template>

<script>
import MainOCR from './components/MainOCR.vue'

export default {
  name: 'App',
  components: {
    MainOCR
  }
}
</script>

<style>
#app {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body{
  margin: 0px !important;
}

p{
    line-height: 1.5em;
}

/* 全局列表样式 */
ul, ol {
    list-style-position: outside;
    padding-left: calc(var(--layout-pix__default) * 5);
}

ul>li::marker, ol>li::marker {
    color: #C23535;
}

ol {
    list-style-type: decimal;
}

ul {
    list-style-type: disc;
}

/* 表格样式 */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: white;
}

th, td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
    color: #333;
}

h1{
    font-size: 1.5em;
    line-height: 2.0em;
}

h2{
    font-size: 1.25em;
    line-height: 1.5em;
}

h3 h4 h5{
    font-size: 1.0em;
    line-height: 1.5em;
}


th {
    background-color: #f2f2f2;
    color: #333;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #eef;
}

tr:not(:last-child) td {
    border-bottom: 1px solid #ddd;
}

td:last-child {
    border-right: 1px solid #ddd;
}

.scrollable-content {
    height: 100%;
    overflow-y: scroll;
    /* 总是显示滚动条 */
    /* 确保内容不会被滚动条遮挡 */
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE和Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollable-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari 和 Opera */
}

.scrollable-content:hover {
    overflow-y: scroll;
}

.line50{
    background-color: rgba(55, 53, 47, 0.1);
}

</style>
