<template>
    <div class="image-container">
        <div class="image-wrapper">
            <el-image :src="imageSrc" alt="OCR Image" fit="scale-down">
                <template #error> <!-- 注意这里的修改 -->
                    <div class="image-slot"
                        style="display: flex;align-items: center;justify-content: center;flex-direction: column;height: 100%;padding: 0px 48px;">
                        <i class="el-icon-magic-stick" style="font-size: 32px;margin-bottom: 24px;"></i>
                        <p style="color: rgb(55, 53, 47,0.8);">Initialize shortcut is <b>Option+S</b></p>
                        <p class="pbtn" @click="openSettings">Set My shortcut</p>
                        <!-- <p>通过 Preferences -> Keybindings -> 来修改</p> -->
                    </div>
                </template>
            </el-image>
            <div v-if="imageSrc" class="zoom-button" @click="zoomInImage">
                <i class="el-icon-full-screen"></i>
            </div>
        </div>

        <div v-if="!isInWebView" class="placeholder-container">
            <el-upload class="upload-button" action="" :show-file-list="false" :auto-upload="false"
                :on-change="handleImageUpload" :http-request="customUploadRequest">
                <el-button type="success" icon="el-icon-upload">上传识图</el-button>
            </el-upload>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageUploader',
    props: {
        imageSrc: String,
        isInWebView: Boolean
    },
    methods: {
        handleImageUpload(file) {
            // 通知父组件处理上传逻辑
            console.log('handleImageUpload', file);
            this.$emit('image-upload', file);
        },

        async zoomInImage() {
            // 创建一个临时的 <img> 元素
            const tempImg = document.createElement('img');
            tempImg.src = this.imageSrc; // 将 base64 数据设置为图片的 src 属性

            // 创建一个临时的 URL 对象，用于在新窗口中打开图片
            const tempUrl = URL.createObjectURL(this.dataURLtoBlob(this.imageSrc));

            if (this.isInWebView) {
                // 将 Blob URL 转换为 Data URL
                const response = await fetch(tempUrl);
                const blob = await response.blob();
                const reader = new FileReader();
                reader.onloadend = () => {
                    // 发送自定义事件，通知原生代码打开新的浏览器页面
                    if (window.webkit?.messageHandlers?.openImageBase64) {
                        const base64String = this.imageSrc.split(',')[1];
                        window.webkit.messageHandlers.openImageBase64.postMessage(base64String);
                    } else {
                        console.log('vue-message：window.webkit.messageHandlers.openImageBase64 is not available');
                    }
                };
                reader.readAsDataURL(blob);
            } else {
                // 在新窗口中打开临时 URL
                window.open(tempUrl, '_blank');
            }

            // 释放临时 URL 对象
            URL.revokeObjectURL(tempUrl);
        },
        // 将 base64 数据转换为 Blob 对象
        dataURLtoBlob(dataurl) {
            const arr = dataurl.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        customUploadRequest(options) {
            // 通知父组件自定义上传逻辑
            this.$emit('custom-upload', options);
        },
        openSettings() { // 调用父组件的 openSettings 方法 
            this.$emit('open-settings')
        }
    }
}
</script>

<style scoped>
.image-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.placeholder-container {
    height: 48px;
    margin-top: 24px;
}

.image-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 80vh;
    /* 根据需要设置固定高度 */
    overflow: hidden;
}

.pbtn{
    cursor: pointer;
    color: rgb(55, 53, 47,.8);
    font-weight: bold;
}

.pbtn:hover{
    color: rgb(55, 53, 47);
    transform: scale(1.05);
    /* 当鼠标悬停时放大5% */
    transition: transform 0.3s ease;
}

.el-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* 图片等比例缩放，并且不裁剪 */
}

.zoom-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-wrapper:hover .zoom-button {
    opacity: 1;
}
</style>